// Menu.jsx
import React from 'react';
import './Menu.css';
import logo from './assets/logo2.png';
import whatsappIcon from './assets/whatsapp.png';
import cardapio1 from './assets/cardapio2024.pdf';
import cardapio2 from './assets/cardapio2024barra.pdf';
import cardapio3 from './assets/cardapio2025barra.pdf';
const Menu = () => {
  return (
    <div className="menu" id='Menu'>
      <div className="logo-container-menu">
        <img src={logo} alt="Logo Villa Veron" className="logo-menu" />
      </div>
      <div className="branch">
        <h2>Sua pizza favorita está aqui!</h2>
        <div className="location">
          <h3>VILLA VERON <br/></h3>
          <h4>(UNIDADE ARACAJU)</h4>
          <a href={cardapio1} target="_blank" rel="noopener noreferrer">
          <button className="menu-button">CARDÁPIO</button>
          </a>
          <a href='https://xmenu.com.br/pedidos/?loja=18108' target='_blank' without rel="noreferrer">
          <button className="order-button">PEÇA JÁ</button>
          </a>
          <a href='https://api.whatsapp.com/send?phone=557930858555&text=Olá,%20gostaria%20de%20fazer%20um%20pedido.' target='_blank' without rel="noreferrer">
          <button className="order-button">
            PEÇA JÁ
            <img src={whatsappIcon} alt="WhatsApp" className="whatsapp-icon" />
            </button>
            </a>
        </div>
        <div className="divider"></div>
        <div className="location">
          <h3>VILLA VERON BARRA <br/></h3>
          <h4>(UNIDADE BARRA DOS COQUEIROS)</h4>
          <a href={cardapio3} target="_blank" rel="noopener noreferrer">
          <button className="menu-button">CARDÁPIO</button>
          </a>
          <button className="order-button">PEÇA JÁ</button>
          <a href='https://api.whatsapp.com/send?phone=5579999928777&text=Olá,%20gostaria%20de%20fazer%20um%20pedido.' target='_blank' without rel="noreferrer">
          <button className="order-button">
            PEÇA JÁ
            <img src={whatsappIcon} alt="WhatsApp" className="whatsapp-icon" />
            </button>
            </a>
        </div>
      </div>
    </div>
  );
}

export default Menu;
